import UnderlinedTitle from "../../components/general/UnderlinedTitled";

export default function Settings() {

    
    
    return (
        <>
        <div className="messages">
            <UnderlinedTitle title="Messages" />  
           
        </div>
          
        </>
    );
}